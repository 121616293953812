var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      staticClass: "mx-n6",
      attrs: { vertical: "", flat: "" },
      model: {
        value: _vm.currentStep,
        callback: function($$v) {
          _vm.currentStep = $$v
        },
        expression: "currentStep"
      }
    },
    [
      _c(
        "v-stepper-step",
        { attrs: { step: "1", complete: _vm.currentStep > 1 } },
        [
          _vm._v(" Save Your Proposal "),
          _c("small", [
            _vm._v("Add the details for the account you want it sent under")
          ])
        ]
      ),
      _c(
        "v-stepper-content",
        { attrs: { step: "1" } },
        [
          _c("AccountCreator", {
            ref: "accountCreator",
            on: {
              saving: function(val) {
                return (_vm.savingAccount = val)
              },
              complete: function($event) {
                _vm.currentStep = 2
              },
              enterPress: _vm.createAccount
            }
          }),
          _c(
            "v-btn",
            {
              staticClass:
                "float-right mr-2 mb-2 action-signup-and-send-continue",
              attrs: { loading: _vm.savingAccount },
              on: { click: _vm.createAccount }
            },
            [_vm._v(" Continue ")]
          )
        ],
        1
      ),
      _c(
        "v-stepper-step",
        { attrs: { step: "2", complete: _vm.currentStep > 2 } },
        [_vm._v(" Send Your Proposal ")]
      ),
      _c(
        "v-stepper-content",
        { attrs: { step: "2" } },
        [_c("SendProposal", { ref: "sendProposal" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }