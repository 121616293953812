<template>
  <v-stepper vertical flat class="mx-n6" v-model="currentStep">
    <v-stepper-step step="1" :complete="currentStep > 1">
      Save Your Proposal
      <small>Add the details for the account you want it sent under</small>
    </v-stepper-step>
    <v-stepper-content step="1">
      <AccountCreator
        ref="accountCreator"
        @saving="val => savingAccount = val"
        @complete="currentStep = 2"
        @enterPress="createAccount"
      />
      <v-btn
        class="float-right mr-2 mb-2 action-signup-and-send-continue"
        :loading="savingAccount"
        @click="createAccount"
      >
        Continue
      </v-btn>
    </v-stepper-content>

    <v-stepper-step step="2" :complete="currentStep > 2">
      Send Your Proposal
    </v-stepper-step>
    <v-stepper-content step="2">
      <SendProposal ref="sendProposal"/>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import AccountCreator from './account-creator';
import SendProposal from './send-proposal-email-interface';

export default {
  name: 'signup-and-send',

  components: {
    AccountCreator,
    SendProposal,
  },

  data() {
    return {
      currentStep: 1,
      savingAccount: false,
      saveBtnEnabled: false,
    };
  },

  methods: {
    createAccount() {
      this.$refs.accountCreator.save(() => this.currentStep = 2);
    },
    save(close) {
      this.$refs.sendProposal.save(close);
    },
  },

  mounted() {
    this.$watch(() => {
      return this.$refs.sendProposal.saveBtnEnabled;
    }, (saveBtnEnabled) => {
      if (this.currentStep === 2) {
        this.saveBtnEnabled = saveBtnEnabled;
      }
    });
    this.saveBtnEnabled = this.currentStep === 2 && Boolean(this.$refs.sendProposal.saveBtnEnabled);
  },

  watch: {
    currentStep(cs) {
      this.saveBtnEnabled = cs === 2 && this.$refs.sendProposal.saveBtnEnabled;
    },
  },
};
</script>
